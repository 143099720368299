import { CATEGORIES_COLORS } from '@/constants'

interface BallProps extends React.SVGProps<SVGSVGElement> {
  category?: string
}

function findCategoryColor(category: string) {
  let color: string = ''
  Object.values(CATEGORIES_COLORS).forEach(value => {
    const isCategory = value.categories.find(
      c => c.toLowerCase() === category.toLowerCase()
    )
    if (isCategory) {
      color = value.color
    }
  })
  return color
}

const Ball = (props: BallProps) => {
  const color = props.fill || findCategoryColor(props.category || '')
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 549 549"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#prefix__filter0_f_349:2266)">
        <circle cx={274.5} cy={274.5} r={214.5} fill={color || '#3163FC'} />
      </g>
      <defs>
        <filter
          id="prefix__filter0_f_349:2266"
          x={0}
          y={0}
          width={549}
          height={549}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            stdDeviation={30}
            result="effect1_foregroundBlur_349:2266"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default Ball
