import { StaticImage } from 'gatsby-plugin-image'

const UCImage = () => {
  return (
    <StaticImage
      src="../../images/uc_line.png"
      alt="Under Construction"
      className="scale-[2] tablet:scale-150 desktop:scale-100 transform"
    />
  )
}

export default UCImage
