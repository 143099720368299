import { motion } from 'framer-motion'
import { useBreakpoints } from '@/hooks/useBreakpoints'
import Ball from '@/components/shared/Ball'
import UCImage from '@/components/shared/UCImage'

const NotFoundPage = () => {
  const { isDesktop } = useBreakpoints()
  const ballSize = isDesktop ? 550 : 480
  const ballPosition = isDesktop ? 0 : -300

  return (
    <>
      <div className="flex flex-col overflow-hidden">
        <div className="relative flex base-container">
          <div className="flex flex-col space-y-8 desktop:w-1/2">
            <h1 className="heading-2 md:heading-1">
              Nội dung đang được xây dựng
            </h1>
            <div className="subheading-5 md:heading-4">
              Bạn biết không? Nguyên team 40 con người nhà VHL đang ngày đêm suy
              nghĩ, tìm tài liệu viết nhiều bài chất lượng hơn này.
              <br />
              <br />
              Cùng chờ đón các bài viết mới từ VHL nhé!
            </div>
          </div>
          <motion.span
            style={{ zIndex: -1, position: 'absolute' }}
            initial={{ top: 0, right: ballPosition }}
            animate={{ top: [0, 300, 0] }}
            transition={{ type: 'spring', duration: 3.5, repeat: Infinity }}
          >
            <Ball width={ballSize} height={ballSize} fill="#FF813B" />
          </motion.span>
        </div>
        <div className="pb-20">
          <UCImage />
        </div>
      </div>
    </>
  )
}

export default NotFoundPage
